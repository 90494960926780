<script setup>
import { paginationMeta } from "@/@core/utils/paginationMeta"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import { VDataTableServer } from "vuetify/labs/VDataTable"

// import EpisodeDrawer from '@/views/incident/master/EpisodeDrawer.vue'
import EpisodeService from "@/services/episode.service"
import { PerfectScrollbar } from "vue3-perfect-scrollbar"
import { AbortController } from "ofetch"
import { watch, onMounted, onUnmounted } from "vue"

import echo from "@/@core/utils/echo"

const searchQuery = ref("")
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: "app-master-episode",

    // subject: 'master.form_type',
    // action: 'read',
  },
})

const snackbar = useToast()

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: "Code",
    key: "code",
  },
  {
    title: "Patient",
    key: "patient",
  },
  {
    title: "Doctor",
    key: "doctor_name",
  },
  {
    title: "Form Type",
    key: "form_type.name",
  },
  {
    title: "Location",
    key: "location.name",
  },
  {
    title: "Visited Date",
    key: "visit_date_time",
  },
  {
    title: "Discharge Date",
    key: "discharge_date",
  },

  // {
  //   title: 'Actions',
  //   key: 'actions',
  //   sortable: false,
  // },
]

const { data: episodeListing, execute: fetchEpisodeListing } = await useApi(
  createUrl("/masters/episodes", {
    query: {
      paged: 1,
      q: searchQuery,
      itemsPerPage,
      page,
      sort: sortBy,
      order_by: orderBy,
    },
  }),
)

const categories = computed(() => episodeListing.value?.data)
const totalCategories = computed(() => episodeListing.value?.meta?.total)

const isDrawerVisible = ref(false)
const isViewOnly = ref(false)
const investigationGroupData = ref()

const showEpisode = (data = null, viewOnly = false) => {
  investigationGroupData.value = data
  isDrawerVisible.value = true
  isViewOnly.value = viewOnly
}

watch(isDrawerVisible, value => {
  if (value === false) {
    investigationGroupData.value = null
    isViewOnly.value = false
  }
})

const refConfirmDialog = ref()

const showConfirmDialog = data => {
  investigationGroupData.value = data
  isConfirmDialogVisible.value = true
}

const deleteEpisode = async (confirm, data) => {
  if (data?.id && confirm) {
    await EpisodeService.destroy(
      data.id,
      () => {
        refConfirmDialog.value.onSuccess()
        isConfirmDialogVisible.value = false
        fetchEpisodeListing()
      },
      () => {
        refConfirmDialog.value.onError()
      },
    )
  }
}

watch(isConfirmDialogVisible, value => {
  if (value === false) investigationGroupData.value = null
})

const refImportForm = ref()
const importFile = ref()
const importErrors = ref()
const importStatus = ref({})
const isImportProcessing = ref(false)
const isImportLoading = ref(false)
const isImportModal = ref(false)

const submitImport = async () => {
  isImportLoading.value = true
  await EpisodeService.import(
    {
      file: importFile.value,
    },
    () => {
      importErrors.value = null
      isImportProcessing.value = true
    },
    error => {
      importErrors.value = error.data.errors
    },
  )
  isImportLoading.value = false
}

// const formatImportErrors = errors => {
//   if (!errors) return []

//   const groupedErrors = Object.entries(errors).reduce(
//     (acc, [key, messages]) => {
//       const [row, column] = key.split(".")
//       const displayRow = Number(row) + 1
//       if (!acc[row]) acc[row] = []
//       acc[row].push(
//         ...messages.map(message => ({
//           title: `Row No ${displayRow}: ${column
//             .replace(/_/g, " ")
//             .replace(/^\w/, c => c.toUpperCase())} ${message
//             .replace(`The ${key} field`, "")
//             .trim()}`,

//           // title: `In row ${row} ${column.replace(/_/g, ' ').replace(/^\w/, c => c.toLowerCase())} column`,
//           // message: message.replace(`The ${key} field`, 'Field')
//         })),
//       )
      
//       return acc
//     },
//     {},
//   )

//   return Object.keys(groupedErrors)
//     .sort((a, b) => Number(a) - Number(b)) // Sort rows in numerical order
//     .flatMap(row => groupedErrors[row])
// }

const downloadSampleFile = async () => {
  await downloadFile(
    "masters/episodes/sample-import-file/export",
    "episode-sample-excel.xlsx",
    () => {}, // success
    () => {}, // error
  )
}

watch(
  () => isImportModal.value,
  value => {
    if (value === false) {
      importFile.value = null
      importErrors.value = null
    }
  },
)

watch(
  () => importStatus.value,
  value => {
    switch (value.status) {
    case "completed":
      importFile.value = null
      importErrors.value = null
      importStatus.value = {}
      isImportProcessing.value = false
      isImportLoading.value = false
      isImportModal.value = false
      fetchEpisodeListing()
      snackbar.success("Episodes imported successfully")
      break

    case "validation_failed":
      importErrors.value = value.errors
      importStatus.value = {}
      isImportProcessing.value = false
      isImportLoading.value = false
      snackbar.error("Excel file validation failed")
      break

    case "failed":
      importErrors.value = value.errors
      importStatus.value = {}
      isImportProcessing.value = false
      isImportLoading.value = false
      snackbar.error("Error during importing episodes")
      break

    default:
      // do nothing
      break
    }
  },
  { deep: true },
)

echo
  .private("episode-import-status")
  .listen("EpisodeImportStatusEvent", e => {
    console.log(e)
    importStatus.value = e
  })

// const channel = echo.private("test-channel").connect();
// console.log(echo)

// echo.private(`test-channel`)
//     .listen('BroadTestEvent', (data) => {
//         console.log('Event Data:', data);
//     });

//     echo.private(`test-channel`).listen("BroadTestEvent",
//   (e) => console.log(e)
// );
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <!--
            <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
            >
            Export
            </VBtn> 
          -->

          <VBtn
            v-if="$can('create', 'master.form_type')"
            prepend-icon="tabler-plus"
            @click="isImportModal = !isImportModal"
          >
            Import Episode
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="categories"
        :items-length="totalCategories"
        :headers="headers"
        :fixed-header="true"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.patient="{ item }">
          <VMenu
            v-if="item.customer"
            open-on-hover
            :close-on-content-click="false"
          >
            <template #activator="{ props }">
              <VChip v-bind="props">
                {{ item.customer.name }}
              </VChip>
            </template>
            <VCard max-width="350">
              <VList>
                <VListItem class="mx-0">
                  <template #title>
                    <VListItemTitle class="text-h6 text-primary">
                      {{ item.customer.code }}
                    </VListItemTitle>
                  </template>
                  <template #subtitle>
                    <template v-if="item.customer?.uhid">
                      <VListItemTitle>
                        UHID:
                        {{ item.customer.uhid }}
                      </VListItemTitle>
                    </template>
                    <template v-if="item.customer?.name">
                      <VListItemTitle>
                        Name:
                        {{ item.customer.name }}
                      </VListItemTitle>
                    </template>
                    <template v-if="item.customer?.phone">
                      <VListItemTitle>
                        Phone No:
                        {{ item.customer.phone }}
                      </VListItemTitle>
                    </template>
                    <template v-if="item.customer?.email">
                      <VListItemTitle>
                        Email:
                        {{ item.customer.email }}
                      </VListItemTitle>
                    </template>
                  </template>
                </VListItem>
              </VList>
              <VDivider />
            </VCard>
          </VMenu>
        </template>
        <template #item.visit_date_time="{ item }">
          {{ formatDate(item.visit_date_time) }}
        </template>
        <template #item.discharge_date="{ item }">
          {{ formatDate(item.discharge_date) }}
        </template>

        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn
            v-if="$can('delete', 'master.form_type')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn
            v-if="$can('update', 'master.form_type')"
            @click="showEpisode(item)"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalCategories) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalCategories / itemsPerPage)"
              :total-visible="5"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>
              <!--
                <template #item="slotProps">
                <VBtn
                variant="outlined"
                color="default"
                v-bind="slotProps"
                :icon="false"
                >
                {{ slotProps.page }}
                </VBtn>
                </template> 
              -->
              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>

  <!-- Import Modal -->
  <VDialog
    v-model="isImportModal"
    max-width="600"
    :persistent="isImportLoading || isImportProcessing"
  >
    <DialogCloseBtn
      size="small"
      :disabled="isImportLoading || isImportProcessing"
      @click="() => (isImportModal = !isImportModal)"
    />
    <VCard>
      <VCardText>
        <h6 class="text-lg font-weight-medium">
          Import Episode
        </h6>
      </VCardText>

      <VCardText>
        <VForm ref="refImportForm">
          <VFileInput
            label="Upload File"
            prepend-icon="tabler-file-import"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :disabled="isImportLoading || isImportProcessing"
            :error-messages="importErrors?.file"
            @change="
              (e) => {
                importFile = e.target.files[0];
              }
            "
          />
        </VForm>
      </VCardText>

      <!-- Errors -->
      <VCardText v-if="importErrors?.message">
        <VAlert
          color="error"
          variant="tonal"
          closable
        >
          <template #text>
            {{ importErrors.message }}
          </template>
        </VAlert>
      </VCardText>

      <!-- Validation Errors -->
      <VCardText v-if="importErrors && importErrors.length > 0">
        <div class="d-flex flex-column gap-2">
          <VAlert
            v-for="(error, index) in importErrors"
            :key="index"
            color="error"
            variant="tonal"
            closable
          >
            <!--
              <template #title>
              <div class="d-flex flex-column">
              <span class="text-h6 font-weight-bold error--text">
              {{ error.title }}
              </span>
              <span class="text-h6">{{ error.message }}</span>
              </div>
              </template> 
            -->
            <template #text>
              {{ error }}
            </template>
          </VAlert>
        </div>
      </VCardText>

      <!-- Progress Bar -->
      <VCardText v-if="isImportProcessing || importStatus.progress">
        <VProgressLinear
          v-if="importStatus.progress"
          height="4"
          buffer-value="0"
          color="primary"
          :model-value="importStatus.progress"
          stream
          rounded
        />
        <VProgressLinear
          v-else
          color="primary"
          height="4"
          indeterminate
          rounded
        />
      </VCardText>

      <!-- Action -->
      <VCardText>
        <div class="d-flex gap-2">
          <VBtn
            class="me-auto"
            variant="tonal"
            color="secondary"
            :disabled="isImportLoading || isImportProcessing"
            @click="downloadSampleFile"
          >
            Download Sample Excel
          </VBtn>
          <VBtn
            variant="tonal"
            color="error"
            :disabled="isImportLoading || isImportProcessing"
            @click="isImportModal = false"
          >
            Close
          </VBtn>
          <VBtn
            variant="tonal"
            :disabled="isImportLoading || isImportProcessing"
            @click="submitImport"
          >
            Import
          </VBtn>
        </div>
      </VCardText>
    </VCard>
  </VDialog>

  <!--
    <ConfirmDialog
    ref="refConfirmDialog"
    v-model:isDialogVisible="isConfirmDialogVisible"
    confirmation-question="Are you sure?"
    confirm-msg="Episode Deleted."
    confirm-title="Deleted!"
    cancel-title="Error!"
    cancel-msg="Something Went Wrong."
    :is-confirm-visible="false"
    :is-error-visible="false"
    :data="investigationGroupData"
    @confirm="deleteEpisode"
    /> 
  -->
</template>
