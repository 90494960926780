<script setup>
import QuestionSettings from '@/views/pages/question/QuestionSettings.vue'

const props = defineProps({
  id: {
    type: String,
    default: null,
    required: false,
  },

})

definePage({})

const route = useRoute('users')
const router = useRouter()

const {
  data: questionData,
} = await useApi(createUrl(`/masters/questions/${route.params?.id}`), {
  beforeFetch: ({ cancel }) => {
    if (!route.params?.id) cancel()
  },
  onFetchError: error => {
    router.push({
      name: 'invalid-url',
      params: { code: 404 },
    })
  },
})

// watch(() => route, async route => {
//   if (route.params?.id) {
//     questionData.value = await useApi(`/masters/questions/${ route.params?.id }`)?.data
//   }
// }, { immediate: true, deep: true })

// onMounted(() => {
//   questionData.value = useApi(`/masters/questions/${ route.params?.id }`).data
// })
</script>

<template>
  <QuestionSettings :question-data="questionData" />
  <!-- <div>
    <div v-if="route.params?.id">
      <QuestionSettings :question-data="questionData" />
    </div>
    <div v-else-if="!route.params?.id">
      <QuestionSettings :question-data="questionData" />
    </div>
    <VCard v-else>
      <VCardTitle class="text-center">
        <VRow align="center">
          <VCol>
            <VIcon
              icon="tabler-alert-triangle"
              size="24"
            />
            User Not Found
          </VCol>
        </VRow>
        <VBtn
          color="primary"
          class="mt-3"
          @click="router.go(-1)"
        >
          Go Back
        </VBtn>
      </VCardTitle>
    </VCard>
  </div> -->
</template>
